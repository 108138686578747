// extracted by mini-css-extract-plugin
export var header = "header-module--header--G4zrc";
export var headerInner = "header-module--headerInner--Eis74";
export var headerInnerTitle = "header-module--headerInnerTitle--AcxsV";
export var headerInnerLogo = "header-module--headerInnerLogo--xjBY5";
export var headerInnerCatch = "header-module--headerInnerCatch--YwaBQ";
export var headerInnerFunctions = "header-module--headerInnerFunctions--ZEhU1";
export var headerNavLink = "header-module--headerNavLink--Oyc9-";
export var hover = "header-module--hover--kYQPb";
export var headerNavLanguage = "header-module--headerNavLanguage--HPD0A";
export var headerNavChild = "header-module--headerNavChild--HrvxV";
export var hoverOpen = "header-module--hoverOpen--F7vh3";
export var fadeIn = "header-module--fadeIn--15bzE";
export var hoverClose = "header-module--hoverClose--AiB1E";
export var fadeOut = "header-module--fadeOut--It83g";
export var headerNavChildInner = "header-module--headerNavChildInner--ZvUMF";
export var spMenuTrigger = "header-module--spMenuTrigger--dH6ey";
export var spMenuTriggerHamburger = "header-module--spMenuTriggerHamburger--j2Nki";
export var active = "header-module--active--yM4F8";