import React from "react"
import { Link } from "gatsby"
import SpMenu from "./sp-menu"
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../../styles/components/header.module.scss'

import Search from "../search"
const searchIndices = [{ name: process.env.ALGOLIA_INDEX_NAME, title: `Pages` }]

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isHover: false
    }
    this.spMenuToggle = this.spMenuToggle.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onTouchStart = this.onTouchStart.bind(this)
    this.onTouchEnd   = this.onTouchEnd.bind(this)
  }

  spMenuToggle(e) {
    e.preventDefault()
    if (this.state.isOpen) {
      this.setState({isOpen : false})
    } else {
      this.setState({isOpen : true})
    }
  }

  onMouseEnter(e) {
    this.setState({ isHover: true })
  }
  onMouseLeave(e) {
    this.setState({ isHover: false })
  }
  onTouchStart(e) {
    this.setState({ isHover: true })
  }
  onTouchEnd(e) {
    this.setState({ isHover: false })
  }

  render() {
    return (
      <header className={styles.header} role="menuitem" tabIndex="-1" onMouseLeave={this.onMouseLeave}>
        <div className={styles.headerInner}>
          <div className={styles.headerInnerTitle}>
            {/* ToDo: 以下後で戻す Linkに戻す */}
            <a href="/" className={styles.headerInnerLogo}>
              <StaticImage src={'../../images/logo.svg'} alt="NEDO" placeholder="blurred" width={128} />
            </a>
            <span className={styles.headerInnerCatch}>Green Japan, <br />Green Innovation</span>
          </div>
          <div className={styles.headerInnerFunctions}>
            <ul>
              <li className={`${this.state.isHover ? `${styles.headerNavLink} ${styles.hover}` : styles.headerNavLink}`}>
                <Link to="/about/"
                  onMouseEnter={this.onMouseEnter}
                  onFocus={this.onMouseEnter}
                  onTouchStart={this.onTouchStart}
                  onTouchEnd={this.onTouchEnd}
                >グリーンイノベーション基金事業概要等</Link>
              </li>
              <li className={styles.headerNavLink}><Link to="/project/">プロジェクト情報</Link></li>
              <li className={styles.headerNavLink}><Link to="/dashboard/">ダッシュボード</Link></li>
              <li className={styles.headerNavLink}><Link to="/contact/">お問い合わせ</Link></li>
              <li className={`${styles.headerNavLink} ${styles.headerNavLanguage}`}><a href="/en/">English</a></li>
              {/*<li className={styles.headerNavLink}><Link to="/opinion-box/">オピニオンボックス</Link></li>*/}
              </ul>
            <Search indices={searchIndices} />
          </div>
          <a id="spMenuTrigger" href="#sp" className={styles.spMenuTrigger} onClick={this.spMenuToggle}>
            <ul className={styles.spMenuTriggerHamburger}>
              <li className={`${this.state.isOpen ? styles.active : ''}`}></li>
              <li className={`${this.state.isOpen ? styles.active : ''}`}></li>
              <li className={`${this.state.isOpen ? styles.active : ''}`}></li>
            </ul>
          </a>
          <SpMenu openFlag={this.state.isOpen}></SpMenu>
        </div>
        <div
          role="menuitem"
          tabIndex="-1"
          onMouseLeave={this.onMouseLeave}
          className={`${this.state.isHover ? `${styles.headerNavChild} ${styles.hoverOpen}` : `${styles.headerNavChild} ${styles.hoverClose}`}`}>
          <div className={styles.headerNavChildInner}>
            <div>
              <ul>
                <li><Link tabIndex="0" to="/about/">グリーンイノベーション基金事業概要</Link></li>
                <li><a href="https://www.meti.go.jp/shingikai/sankoshin/green_innovation/index.html" target="_blank" rel="noreferrer noopener">グリーンイノベーションプロジェクト部会情報</a></li>
                <li><Link tabIndex="0" to="/about/public-contribution/">公募等情報</Link></li>
                <li><Link tabIndex="0" onBlur={this.onMouseLeave} to="/about/related-information/">関連制度・リンク</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
export default Header