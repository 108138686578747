// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--BXC1A";
export var footerInner = "footer-module--footerInner--WroxQ";
export var footerInnerLeft = "footer-module--footerInnerLeft--h4N5X";
export var footerInnerMiddle = "footer-module--footerInnerMiddle--rUDcg";
export var footerInnerRight = "footer-module--footerInnerRight--x4gPi";
export var footerCatch = "footer-module--footerCatch--BN61K";
export var footerBtn = "footer-module--footerBtn--eEMXU";
export var footerNav = "footer-module--footerNav--LDI0H";
export var footerNavLink = "footer-module--footerNavLink--wNtnR";
export var footerNavTitle = "footer-module--footerNavTitle--TZIcy";
export var footerSns = "footer-module--footerSns--vsy-T";
export var footerSnsLink = "footer-module--footerSnsLink--qjKW-";
export var footerOutbound = "footer-module--footerOutbound--BtGMh";
export var footerOutboundLink = "footer-module--footerOutboundLink---ALLS";
export var footerCopyright = "footer-module--footerCopyright--ZJXK7";