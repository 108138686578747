import React from "react"
import { Link } from "gatsby"
import * as styles from "../../styles/components/sp-menu.module.scss"

import Search from "../search"
const searchIndices = [{ name: process.env.ALGOLIA_INDEX_NAME, title: `Pages` }]

class SpMenu extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      
    }
  }
  render() {
    return (
      <div
        id="spMenu"
        aria-hidden={this.props.openFlag ? false : true}
        className={`${styles.spMenu} ${this.props.openFlag ? styles.open : ''}`}>
        <div className={styles.spMenuInner}>
          <a className={styles.spMenuLanguage} href="/en/">English</a>
          <Search indices={searchIndices} />
          <ul className={styles.spMenuNav}>
            <li className={styles.spMenuNavLink}><Link to="/about/">グリーンイノベーション基金事業概要等</Link>
              <ul className={styles.spMenuNav}>
                <li className={styles.spMenuNavLink}><Link to="/about/">グリーンイノベーション基金事業概要</Link></li>
                <li className={styles.spMenuNavLink}><a href="https://www.meti.go.jp/shingikai/sankoshin/green_innovation/index.html" target="_blank" rel="noreferrer noopener">グリーンイノベーションプロジェクト部会情報</a></li>
                <li className={styles.spMenuNavLink}><Link to="/about/public-contribution/">公募等情報</Link></li>
                <li className={styles.spMenuNavLink}><Link to="/about/related-information/">関連制度・リンク</Link></li>
              </ul>
            </li>              
            <li className={styles.spMenuNavLink}><Link to="/project/">プロジェクト情報</Link></li>
            {/*<li className={styles.spMenuNavLink}><Link to="#">特集記事</Link></li>*/}
            <li className={styles.spMenuNavLink}><Link to="/information/">お知らせ</Link></li>
            <li className={styles.spMenuNavLink}><Link to="/dashboard/">ダッシュボード</Link></li>
            <li className={styles.spMenuNavLink}><Link to="/contact/">お問い合わせ</Link></li>
            {/*<li className={styles.spMenuNavLink}><Link to="/opinion-box/">オピニオンボックス</Link></li>*/}
            <li className={styles.spMenuNavLink}><a href="https://www.meti.go.jp/intro/consult/disclosure/a_main.html" target="_blank" rel="noreferrer noopener">情報公開</a></li>
            <li className={styles.spMenuNavLink}><a href="https://www.nedo.go.jp/qinf/copyright.html" target="_blank" rel="noreferrer noopener">サイト利用について</a></li>
            <li className={styles.spMenuNavLink}><a href="https://www.nedo.go.jp/qinf/privacy.html" target="_blank" rel="noreferrer noopener">プライバシーポリシー</a></li>
          </ul>
        </div>
      </div>
    )
  }
}
export default SpMenu