import React from "react"
import { Helmet } from "react-helmet"

export default function Meta( props ) {
  const baseTitle = 'NEDO グリーンイノベーション基金'
  const title = props.title ? `${props.title} | ${baseTitle}` : baseTitle
  const description = props.description ? props.description : "2020年10月、我が国は「2050年カーボンニュートラル」を宣言し、2050年までに、温室効果ガスの排出を全体としてゼロにする目標を掲げました。この目標は、従来の政府方針を大幅に前倒すものであり、並大抵の努力で実現できるものではありません。エネルギー・産業部門の構造転換や、大胆な投資によるイノベーションといった現行の取組を大幅に加速することが必要です。 このため、グリーンイノベーション基金事業（以下「基金事業」という。）により、NEDOに2兆円の基金を造成し、官民で野心的かつ具体的な目標を共有した上で、これに経営課題として取り組む企業等に対して、10年間、研究開発・実証から社会実装までを継続して支援します。"
  const siteUrl = `https://${process.env.HOST_NAME}`
  const ogImage = props.ogImage ? props.ogImage : `${siteUrl}/images/og.png`
  const ogUrl = props.path ? `${siteUrl}${props.path}` : siteUrl

  return (
    <Helmet>
      <html lang="ja" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:alt" content={baseTitle} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:site_name" content={baseTitle} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={ogImage} />
      <link rel="icon" href="/images/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="/images/apple-touch-icon.png" />
      <link rel="canonical" href={ogUrl} />
      <meta name='robots' content='max-image-preview:large' />
      <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;500;700;900&family=Roboto:wght@500&display=swap" rel="stylesheet" />
    </Helmet>
  )
}