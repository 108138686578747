import React from "react"
import Header from "./header"
import Footer from "./footer"
import Scroll from "../parts/to-page-top"

export default function Layout( { children }) {
  return (
    <> 
      <Header />        
        { children }
      <Footer />
      <Scroll showBelow={180} />
    </>
  )
}