import React from "react"
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from "../../styles/components/footer.module.scss"
import * as buttonStyles from "../../styles/components/button.module.scss"

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        
        <div className={styles.footerInnerLeft}>
          <div className={styles.footerCatch}>Green Japan, Green Innovation</div>
          <a className={`${styles.footerBtn} ${buttonStyles.btn} ${buttonStyles.btnGradient} w-80p`} href="https://www.nedo.go.jp/itaku-gyomu/ZZRM_100001_00029.html" target="_blank" rel="noreferrer">
            グリーンイノベーション基金事業<br />委託・助成事業の手続き
          </a>
          <Link to="/contact/" className={`${styles.footerBtn} ${buttonStyles.btn} ${buttonStyles.btnGradient} w-80p`}>
            お問い合わせ
          </Link>
          <a className={`${styles.footerBtn} ${buttonStyles.btn} ${buttonStyles.btnGradient} w-80p`} href="https://protect-eu.mimecast.com/s/8pFvCOEEWfpyxl2hvOqxn?domain=meti.go.jp" target="_blank" rel="noreferrer">
            経済産業省<br /><small>グリーンイノベーション基金事業関連サイト</small>
          </a>
        </div>

        <div className={styles.footerInnerMiddle}>
          <h5 className={styles.footerNavTitle}>Contents</h5>
          <ul className={styles.footerNav}>
            <li className={styles.footerNavLink}><a href="/">トップページ</a></li>
            <li className={styles.footerNavLink}><Link to="/about/">グリーンイノベーション基金事業概要等</Link>
              <ul className={styles.footerNav}>
                <li className={styles.footerNavLink}><Link to="/about/">グリーンイノベーション基金事業概要</Link></li>
                <li className={styles.footerNavLink}><a href="https://www.meti.go.jp/shingikai/sankoshin/green_innovation/index.html" target="_blank" rel="noreferrer noopener">グリーンイノベーションプロジェクト部会情報</a></li>
                <li className={styles.footerNavLink}><Link to="/about/public-contribution/">公募等情報</Link></li>
                <li className={styles.footerNavLink}><Link to="/about/related-information/">関連制度・リンク</Link></li>
              </ul>
            </li>
            <li className={styles.footerNavLink}><Link to="/project/">プロジェクト情報</Link></li>
            <li className={styles.footerNavLink}><Link to="/information/">お知らせ</Link></li>
            <li className={styles.footerNavLink}><Link to="/dashboard/">ダッシュボード</Link></li>
            {/*<li className={styles.footerNavLink}><Link to="/opinion-box/">オピニオンボックス</Link></li>*/}
          </ul>
          <ul className={styles.footerNav}>
            <li className={styles.footerNavLink}><a href="https://www.nedo.go.jp/jyouhoukoukai/index.html" target="_blank" rel="noreferrer">情報公開</a></li>
            <li className={styles.footerNavLink}><a href="https://www.nedo.go.jp/qinf/copyright.html" target="_blank" rel="noreferrer">サイト利用について</a></li>
            <li className={styles.footerNavLink}><Link to="/privacy/">プライバシーポリシー</Link></li>
          </ul>
        </div>

        <div className={styles.footerInnerRight}>
          <ul className={styles.footerSns}>
            <li className={styles.footerSnsLink}><a href="https://twitter.com/nedo_info" target="_blank" rel="noreferrer" title="Twitter">
              <StaticImage src={'../../images/x-logo.svg'} alt="X" placeholder="blurred" layout="fixed" width={30} />
            </a></li>
            <li className={styles.footerSnsLink}><a href="https://www.facebook.com/nedo.fb" target="_blank" rel="noreferrer" title="Facebook">
              <StaticImage src={'../../images/ico-facebook.svg'} alt="Facebook" placeholder="blurred" layout="fixed" width={30} />
            </a></li>
            <li className={styles.footerSnsLink}><a href="https://www.youtube.com/channel/UCd4OTUB8A9PIdNs-vxF5t8g" target="_blank" rel="noreferrer" title="YouTube">
              <StaticImage src={'../../images/ico-youtube.svg'} alt="YouTube" placeholder="blurred" layout="fixed" width={30} />
            </a></li>
          </ul>
          <ul className={styles.footerOutbound}>
            <li className={styles.footerOutboundLink}><a href="https://www.meti.go.jp" target="_blank" rel="noreferrer">
              <StaticImage src={'../../images/bnr_ministry_of_economy.png'} alt="経済産業省 Ministry of Economy, Trade and Industry" placeholder="blurred" width={420} />
            </a></li>
            <li className={styles.footerOutboundLink}><a href="https://www.nedo.go.jp" target="_blank" rel="noreferrer">
              <StaticImage src={'../../images/bnr_nedo.png'} alt="NEDO 国立研究開発法人 新エネルギー・産業技術総合開発機構" placeholder="blurred" width={420} />
            </a></li>
          </ul>
        </div>

      </div>
      <address className={styles.footerCopyright}>
        国立研究開発法人新エネルギー・産業技術総合開発機構 （法人番号 2020005008480）<br className="pcNone" />
        © New Energy and Industrial Technology Development Organization. All rights reserved.
      </address>
    </footer>
  )
}