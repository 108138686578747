import styled, { css } from "styled-components"
import SearchBox from "./search-box"

const open = css`
  width: 14rem;
  background: ${({ theme }) => theme.background};
  cursor: text;
  margin-left: -1.75em;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  @media (max-width: 1024px) {
    width: 98%;
    margin-right: 0.35rem;
    border-box: content-box;
    background: #FFFFFF;
  }
`

const closed = css`
  width: 0;
  background: transparent;
  cursor: pointer;
  margin-left: -1em;
  padding-left: 1em;
  @media (max-width: 1024px) {
    margin-left: -1.75em;
    margin-right: 0.35rem;
    padding: 0.5rem 0.5rem 0.5rem 2rem;  
    width: 98%;
    border-box: content-box;
    background: #FFFFFF;
  }
`

export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0;
  font-size: 0.85rem;
  .SearchInput {
    color: #070304;
    outline: none;
    transition: 100ms;
    border-radius: 0.15rem;
    ::placeholder {
      color: ${({ theme }) => theme.faded};
    }
    ${({ hasFocus }) => (hasFocus ? open : closed)}
  }

  .SearchIcon {
    font-size: 1rem;
    width: 1rem;
    color: ${({ hasFocus }) => (hasFocus ? "#118A25" : "#FFFFFF")};
    pointer-events: none;
    @media (max-width: 1024px) {
      color: #118A25;
    }
  }
`