import styled, { css } from "styled-components"
import SearchResult from "./search-result"

const Popover = css`
  color: #070304;
  max-height: 70vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 100%;
  margin-top: 0.5em;
  width: 120vw;
  max-width: 42em;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
  padding: 1rem;
  border-radius: 0.15rem;
  background: ${({ theme }) => theme.background};
  @media (max-width: 1024px) {
    width: 98%;
    max-width: 98%;
    margin: 0 auto;
    position: fixed;
    left: 0;
    top: 136px;
  }
`

export default styled(SearchResult)`
  display: ${props => (props.show ? `block` : `none`)};
  ${Popover}

  .HitCount {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #cccccc;
    padding: 0 0 0.5rem 0;
    margin: 0 0 0.5rem 0;
  }

  .Hits {
    ul {
      list-style: none;
      margin-left: 0;
    }

    li.ais-Hits-item {
      padding: 0.5rem 0 1.5rem 0;
      border-bottom: 1px dashed #dddddd;
      margin-bottom: 1rem;
      &:first-child {
        padding-top: 1.5rem;
      }
      &:last-child {
        border-bottom: none;
      }
      a {
        color: ${({ theme }) => theme.foreground};

        h4 {
          margin-bottom: 0.35rem;
        }
      }
      .ais-Snippet {
        color: #707070;
        font-size: 0.85rem;
      }
      .ais-Highlight-highlighted, 
      .ais-Snippet-highlighted {
        color: #ffffff;
        background-color: #92c67d !important;
        border-radius: 0.15rem;
        padding: 0 0.25rem;
        margin: 0 0.25rem; 
      }
    }
  }

  .ais-PoweredBy {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.75rem;
    border-top: 1px solid #cccccc;
    padding: 0.5rem 0 0 0;
    margin: 0.5rem 0 0 0;
    svg {
      width: 70px;
      margin-left: 0.25rem;
      @media (max-width: 1024px) {
        path:first-of-type {
          fill: #1A8AF2 !important;
          color: #1A8AF2 !important;  
        }
      }
    }
  }
`