// 文字列変換処理
const stringReplace = (string, replacement) => {
  let result = ''
  replacement.forEach(item => {
    if (string !== null ) {
      if (string.includes(item.from)) {
        let re = new RegExp(item.from, 'g')
        result = string.replace(re, item.to)
      } else {
        result = string
      }  
    }
  })
  return result
}

// 変換処理結果を返す
export default function specialCharacterReplace(string) {
  const subStrings = [
    { from: "CO2", to: "CO<sub>2</sub>" }
  ]
  const supStrings = [
    { from: "Nm3", to: "Nm<sup>3</sup>" }
  ]
  const m3Strings = [
    { from: "m3", to: "m<sup>3</sup>" }
  ]
  let result = ''
  result = stringReplace(string, subStrings)
  result = stringReplace(result, supStrings)
  result = stringReplace(result, m3Strings)

  return result
}